import React, { useState } from 'react'
import axios from 'redaxios'
import { useFormik } from 'formik'
// import Recaptcha from 'react-recaptcha'

const API_PATH = 'https://ksmail-elaxart.vercel.app/api/sendMail.php'
// const MAIL_RECIPIENT = 'schon.florian@gmail.com'
const MAIL_RECIPIENT = 'wennrich@kuboshow.de'
// const RECAPTCHA_SITE_KEY = '6LfTs6MUAAAAAN3FxQlrMSEJai-IS67jjajeaZBM'

const ContactForm = () => {
  const [isErrorShown, setIsErrorShown] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const formik = useFormik({
    initialValues: { name: '', email: '', subject: '', message: '', proof: '' },
    validate: (values) => {
      const errors = {}
      if (!values.name) errors.name = 'Feld benötigt'
      if (!values.email) errors.email = 'Feld benötigt'
      if (!values.subject) errors.subject = 'Feld benötigt'
      if (!values.message) errors.message = 'Nachricht benötigt'
      if (!values.proof) errors.proof = 'Antwort benötigt'
      if (values.proof !== 13) errors.proof = 'Falsche Antwort'
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Fehlende / Ungültige E-Mail-Adresse'
      }
      return errors
    },
    onSubmit: (values, { setSubmitting }) => {
      axios({
        method: 'post',
        url: `${API_PATH}`,
        data: JSON.stringify({
          to: MAIL_RECIPIENT,
          ...values,
        }),
      })
        .then((result) => {
          if (result.data.sent) {
            console.log({
              mailSent: result.data.sent,
            })
            setIsFormSubmitted(true)
          } else {
            setIsErrorShown(true)
          }
        })
        .catch((error) => {
          console.error({ error: error.message })
          setIsErrorShown(true)
        })
        .finally(setSubmitting(false))
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="form-group col-12 col-md-4">
          <input
            id="name"
            placeholder="Name"
            {...formik.getFieldProps('name')}
            type="text"
            className="form-control my-input input-md"
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-danger">{formik.errors.name}</div>
          ) : null}
        </div>
        <div className="form-group col-12 col-md-4">
          <input
            id="email"
            {...formik.getFieldProps('email')}
            placeholder="E-Mail-Adresse"
            type="email"
            className="form-control my-input input-md"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="form-group col-12 col-md-4">
          <input
            id="subject"
            placeholder="Betreff"
            {...formik.getFieldProps('subject')}
            type="text"
            className="form-control my-input input-md"
          />
          {formik.touched.subject && formik.errors.subject ? (
            <div className="text-danger">{formik.errors.subject}</div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <textarea
            id="message"
            placeholder="Ihre Nachricht"
            rows="4"
            className="form-control"
            {...formik.getFieldProps('message')}
            type="text"
          ></textarea>
          {formik.touched.message && formik.errors.message ? (
            <div className="text-danger">{formik.errors.message}</div>
          ) : null}
        </div>
      </div>
      {formik.values.name.length > 0 &&
        formik.values.email.length > 0 &&
        formik.values.message.length > 0 && (
          <>
            <div className="row mt-3 justify-content-center">
              <div className="form-group col-6 col-md-3">
                <label className="control-label my-label" htmlFor="proof">
                  Sicherheitsfrage: Was ist 3 + 1O?
                </label>
              </div>
              <div className="form-group col-4 col-md-2">
                <input
                  id="proof"
                  {...formik.getFieldProps('proof')}
                  type="number"
                  className="form-control my-input input-md"
                />
                {formik.touched.proof && formik.errors.proof ? (
                  <div className="text-danger">{formik.errors.proof}</div>
                ) : null}
              </div>
            </div>
          </>
        )}
      <div className="row justify-content-center">
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={
              formik.isSubmitting ||
              isFormSubmitted ||
              !formik.values.proof ||
              formik.errors.proof
            }
          >
            {formik.isSubmitting && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            <span>ABSENDEN</span>
          </button>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10 col-md-8 text-center">
          {isFormSubmitted && (
            <p className="text-success">
              Ihre Nachricht wurde erfolgreich gesendet. Vielen Dank!
            </p>
          )}

          {isErrorShown && (
            <p className="text-danger">
              Die Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es
              noch einmal zu einem späteren Zeitpunkt oder informieren Sie den
              Administrator der Webseite.
            </p>
          )}
        </div>
      </div>
    </form>
  )
}

export default ContactForm
