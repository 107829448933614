import { graphql, useStaticQuery } from 'gatsby'

export const getImpressions = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityImpression(sort: { fields: title, order: ASC }) {
          nodes {
            _id
            title
            thumbView {
              asset {
                _id
                fluid(maxWidth: 600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            impressionImages {
              _key
              caption
              alt
              asset {
                url
                fluid(maxWidth: 2000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    `
  )
  return data?.allSanityImpression?.nodes || []
}
