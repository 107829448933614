import React from 'react'
import Img from 'gatsby-image'

import PageSection from './PageSection'
import SectionHeaderRow from './SectionHeaderRow'
import { getCatalogues } from '../hooks/getCatalogues'
import './Catalogues.scss'
import useToggle from '../hooks/useToggle'

function Catalogues() {
  const catalogues = getCatalogues()
  const [open, toggle] = useToggle(false)

  return (
    <PageSection id="kataloge">
      <SectionHeaderRow
        headline="Kataloge"
        entryText="Seit 2006 erscheint jedes Jahr ein Katalog zur Messe. Klicken Sie auf ein Cover. Einige Ausgaben sind zu reduzierten Preisen verfügbar."
      />
      <div className="d-flex w-100 justify-content-center">
        <button
          className="btn btn-link"
          href=""
          onClick={() => toggle()}
          style={{ display: open ? 'none' : 'inherit' }}
        >
          Kataloge anzeigen
        </button>
      </div>
      {open ? (
        <div className="row">
          {catalogues.map((c) => (
            <div key={c._id} className="col-2 px-3 pb-3">
              <a
                href={c.catalogueFile.asset.url}
                target="_blank"
                rel="noreferrer"
              >
                <figure className="catalogue-figure">
                  <Img fluid={c.previewImage.asset.fluid} />
                  <div className="catalogue-overlay">
                    <p className="text-white">{'Katalog ' + c.title}</p>
                  </div>
                </figure>
              </a>
              <figcaption className="text-center">{c.title}</figcaption>
            </div>
          ))}
        </div>
      ) : null}
    </PageSection>
  )
}

export default Catalogues
