import { graphql, useStaticQuery } from 'gatsby'

export const getCatalogues = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityCatalogue(sort: { fields: title, order: ASC }) {
          nodes {
            _id
            title
            catalogueFile {
              asset {
                _id
                url
              }
            }
            previewImage {
              asset {
                _id

                fluid(maxWidth: 2000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    `
  )
  return data?.allSanityCatalogue?.nodes || []
}
