import React from 'react'
import Img from 'gatsby-image'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
// import './ril-style.css'

import PageSection from './PageSection'
import SectionHeaderRow from './SectionHeaderRow'
import './Catalogues.scss'
import { getImpressions } from '../hooks/getImpressions'

function Impressions() {
  const impressions = getImpressions()
  console.log(impressions)

  const [photoArray, setPhotoArray] = React.useState([])
  const [photoIndex, setPhotoIndex] = React.useState(0)
  const [isOpen, setIsOpen] = React.useState(false)
  const onOpenClick = (index, array) => {
    setPhotoArray(array)
    setPhotoIndex(index)
    setIsOpen(true)
  }

  return (
    <PageSection id="impressions">
      <SectionHeaderRow
        headline="Impressionen"
        // entryText="Seit 2006 erscheint jedes Jahr ein Katalog zur Messe. Klicken Sie auf ein Cover. Einige Ausgaben sind zu reduzierten Preisen verfügbar."
      />

      <div className="row">
        {impressions.map((imp, index) => (
          <div
            key={imp._id}
            className="col-2 px-3 pb-3"
            onClick={() => onOpenClick(index, imp.impressionImages)}
          >
            <figure className="catalogue-figure">
              <Img fluid={imp.thumbView.asset.fluid} />
              <div className="catalogue-overlay">
                <p className="text-white">{'Messe ' + imp.title}</p>
              </div>
            </figure>
            <figcaption className="text-center">{imp.title}</figcaption>
          </div>
        ))}
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={photoArray[photoIndex].asset.url}
          nextSrc={photoArray[(photoIndex + 1) % photoArray.length].asset.url}
          prevSrc={
            photoArray[(photoIndex + photoArray.length - 1) % photoArray.length]
              .asset.url
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            const newPhotoIndex =
              (photoIndex + photoArray.length - 1) % photoArray.length
            setPhotoIndex(newPhotoIndex)
          }}
          onMoveNextRequest={() => {
            const newPhotoIndex = (photoIndex + 1) % photoArray.length
            setPhotoIndex(newPhotoIndex)
          }}
          // imageCaption={
          //   <Caption artist={artist} artwork={photoArray[photoIndex]} />
          // }
          imagePadding={40}
          enableZoom={false}
        />
      )}
    </PageSection>
  )
}

export default Impressions
