import React from 'react'
import { Link } from 'gatsby'
// import Img from 'gatsby-image'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageSection from '../components/PageSection'
import SectionHeaderRow from '../components/SectionHeaderRow'
import Catalogues from '../components/Catalogues'
import ExhibitionDetails from '../components/ExhibitionDetails'
import Participation from '../components/Participation'
// import Services from '../components/Services'
import ContactForm from '../components/ContactForm'
import ExhibitionFeatures from '../components/ExhibitionFeatures'
import appConfig from '../appConfig'
import Impressions from '../components/Impressions'

// eslint-disable-next-line react/prop-types
function IndexPage({ location }) {
  return (
    <Layout location={location} showSlider={true}>
      <SEO keywords={[`gatsby`, `react`]} title="Home" />
      <PageSection id="messe">
        <SectionHeaderRow headline="Die KUBOSHOW KUNSTMESSE" />
        <div className="row m-n3 justify-content-center align-items-md-center">
          <div className="col-12 col-md-4 p-md-0">
            <img
              src={appConfig.images.globalOverlayStempel}
              alt="KUBOSHOW 2024"
              className="w-100 h-auto d-none d-sm-block"
              // style={{
              //   marginTop: '-22%',
              //   clipPath: 'inset(22% 0 0 0)',
              // }}
            />
            <img
              src={appConfig.images.globalOverlayStempel}
              alt="KUBOSHOW 2024"
              className="w-100 h-auto d-sm-none"
            />
          </div>
          <div className="col-12 col-md-8">
            {/* <p>
              <strong>KUBOSHOW KUNSTMESSE</strong>
            </p> */}
            <p>
              Am 2. & 3. November 2024 findet um 10:00 - 18:00 Uhr die KUBOSHOW KUNSTMESSE in den
              Flottmann-Hallen statt.
            </p>
            <br />
            <p>
              The KUBOSHOW KUNSTMESSE will take place on 2 & 3 November 2024 at 2am - 6pm in
              the Flottmann-Hallen.
            </p>
            <br />
          </div>
        </div>
      </PageSection>
      <PageSection id="to_galleries" className="pt-5">
        <div className="row justify-content-center">
          <div className="col-11 col-sm-10 col-md-5 d-flex flex-column align-items-center">
            <Link to="/gallery-overview" className="mb-5">
            <div
            style={{
              padding: "5px 10px",
              fontSize: "2rem",
              transform: "rotate(6deg)",
              fontFamily: "museo_sans700",
              lineHeight: 1,
            }}
            className="text-uppercase text-white bg-primary">88 Künstler/innen</div>
            </Link>
            <Link to="/gallery-overview">
              <p className="h3">-&gt; zu den Künstlern</p>
            </Link>
          </div>
        </div>
      </PageSection>
      <Catalogues />
      <Impressions />
      <ExhibitionFeatures />
      <PageSection id="zwischen_picture" className="pt-0">
        <div className="row justify-content-center">
          <div className="col-12 col-md-4">
            {/* <Img fluid={} /> */}
            <br />
          </div>
        </div>
      </PageSection>
      <Participation />
      {/* <Services /> */}
      <ExhibitionDetails />
      <PageSection id="kontakt">
        <SectionHeaderRow
          headline="Kontakt"
          entryText="Bei Fragen zur KUBOSHOW KUNSTMESSE und zu den beteiligten Künstlern füllen Sie bitte das Formular aus. Wir werden Ihre Anfrage schnellstmöglich bearbeiten."
        />
        <ContactForm />
      </PageSection>
    </Layout>
  )
}

export default IndexPage
