import { graphql, useStaticQuery } from 'gatsby'

export const getExhibitionDetails = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityExhibitionDetails(sort: { fields: _createdAt, order: ASC }) {
          nodes {
            _id
            title
            _rawText
          }
        }
      }
    `
  )
  return data?.allSanityExhibitionDetails?.nodes || []
}
