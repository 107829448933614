import React from 'react'
import Img from 'gatsby-image'

import PageSection from './PageSection'
import SectionHeaderRow from './SectionHeaderRow'
import { getExhibitionFeatures } from '../hooks/getExhibitionFeatures'

function ExhibitionFeatures() {
  const exhibitionFeatures = getExhibitionFeatures()
  // console.log(exhibitionFeatures)

  return (
    <PageSection id="features">
      <SectionHeaderRow
        headline="Die KUBOSHOW KUNSTMESSE"
        entryText="Mitten im Ruhrgebiet, in Herne, findet in den Flottmann-Hallen, einem ehemaligen Werk für Presslufthämmer, die KUBOSHOW KUNSTMESSE statt. 74 junge internationale Künstler zeigen dort im Herbst ihre aktuellen Arbeiten. Das Teilnehmerfeld wechselt jährlich zu etwa 50%. Das Preisniveau tendiert zwischen 5,- und 4.000,- €. Das Programm ist gegenständlich orientiert und setzt stark auf die Malerei und die Skulptur."
      />
      <div className="row">
        {exhibitionFeatures.map((feature) => (
          <div
            key={feature._id}
            className="col-12 col-sm-6 col-md-4 d-flex flex-column align-items-center text-center mt-5 px-3 py-3"
          >
            <div className="w-30">
              <Img
                className="rounded-circle"
                placeholderClassName="rounded-circle"
                sizes={{
                  ...feature.circleImage.asset.fluid,
                  aspectRatio: 1 / 1,
                }}
              />
            </div>
            <h3 className="my-4">{feature.title}</h3>
            <p className="w-100">{feature.text}</p>
          </div>
        ))}
      </div>
    </PageSection>
  )
}

export default ExhibitionFeatures
