import React from 'react'

import './Participation.scss'
import PageSection from './PageSection'
import SectionHeaderRow from './SectionHeaderRow'
import { Link } from 'gatsby'
import appConfig from '../appConfig'

function Participation() {
  return (
    <PageSection id="teilnahme" dark={true}>
      <SectionHeaderRow headline="Teilnahme" entypoClass="book2" />
      <div className="row no-gutters">
        <div className="col-12 col-md-4">
          <div className="pricing-table">
            <div className="pricebox-header">1. Teilnahme</div>
            <div className="pricebox-feature">
              {/* <p>
                Erfahre mehr über die Teilnahme an der KUBOSHOW KUNSTMESSE. Lies
                die Infos bevor du dich bewirbst.
                <br />
                <Link to="/bedingungen">Hier</Link> Teilnahme- und
                Bewerbungsbedingungen anfordern.
              </p>
              <p>
                Learn more about participating in the KUBOSHOW KUNSTMESSE. Read
                the details before you apply.
                <br />
                Request conditions of participation and application{' '}
                <Link to="/bedingungen">here</Link>.
              </p> */}
              <p>
                Die für 2024 gültigen Teilnahme- und Bewerbungsbedingungen
                kannst du ab ca. Februar 24 anfordern.
              </p>
              <p>
                You can request the conditions of participation and application
                valid for 2024 from around February 24.
              </p>
            </div>
            <div className="pricebox-feature">
              {/* <button className="btn btn-outline-dark text-uppercase px-4 py-2">
                ANFORDERN
              </button> */}
              <Link
                to="/bedingungen"
                className="btn btn-outline-dark text-uppercase px-4 py-2"
              >
                Anfordern
              </Link>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="pricing-table popular">
            <div className="pricebox-header">2. Schnellbewerbung</div>
            <div className="pricebox-feature">
              <p>
                Spare dir Arbeit und maile zunächst einen Link zu deiner
                Homepage oder ein PDF mit mindestens 10 verfügbaren Arbeiten an{' '}
                <a href="mailto:bewerbung@kuboshow.de?subject=Schnellbewerbung für die KUBOSHOW KUNSTMESSE&body=Nutze den  Service ’Schnellbewerbung’. Nach etwa 7 Tagen teilen wir dir mit, ob eine Bewerbung sinnvoll ist oder nicht.">
                  bewerbung@kuboshow.de
                </a>
                . Dieser Service steht ab August wieder zur Verfügung.
              </p>
              <p>
                Save yourself work and first email a link to your homepage or a
                pdf. with at least 10 available works to{' '}
                <a href="mailto:bewerbung@kuboshow.de?subject=Quick application for the KUBOSHOW KUNSTMESSE&body=Use the 'Quick application' service. After about 7 days, we will let you know whether an application makes sense or not.">
                  bewerbung@kuboshow.de
                </a>
                . This service will be available again from August.
              </p>
            </div>
            <div className="pricebox-feature">
              <a
                href="mailto:bewerbung@kuboshow.de?subject=Schnellbewerbung für die KUBOSHOW KUNSTMESSE&body=Nutze den  Service ’Schnellbewerbung’. Nach etwa 7 Tagen teilen wir dir mit, ob eine Bewerbung sinnvoll ist oder nicht."
                target="_self"
                className="btn btn-outline-dark text-uppercase px-4 py-2"
              >
                Mail senden
              </a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="pricing-table">
            <div className="pricebox-header">3. Offizielle Bewerbung</div>
            {appConfig.showApplication ? (
              <div className="pricebox-feature">
                &nbsp;
                <p>
                  <Link to="/bewerbung">Hier </Link>
                  Onlineformular ausfüllen.
                  <br />
                  Fill and submit the online form{' '}
                  <Link to="/bewerbung">here</Link>.
                </p>
                <p className="mt-4 mb-1">Coming back?</p>
                <Link className="px-2 py-2" to="/login">
                  Login mit Künstler-ID / <br />
                  with Artist-ID
                </Link>
              </div>
            ) : appConfig.showApplicationAnnouncement ? (
              <div className="pricebox-feature">
                <p>
                  <strong>KUBOSHOW - ONLY ONLINE</strong>
                </p>
                <p className="my-3 px-5">
                  Die Online-Bewerbung steht ab ca. August 2024 wieder zur Verfügung.
                </p>
                <p className="my-3 px-5">
                  The online application will be available again from around August 2024.
                </p>
              </div>
            ) : appConfig.showApplicationOver ? (
              <div className="pricebox-feature">
                <p>
                  <strong>KUBOSHOW - ONLY ONLINE</strong>
                </p>
                <p  className="my-3 px-5">Die Bewerbungsphase ist für 2024 abgeschlossen.</p>
    <p  className="my-3 px-5">Application period is closed for 2024.</p>
              </div>

            ) : null}
          </div>
        </div>
      </div>
    </PageSection>
  )
}

export default Participation
