import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

import PageSection from './PageSection'
import SectionHeaderRow from './SectionHeaderRow'
import { getExhibitionDetails } from '../hooks/getExhibitionDetails'

function ExhibitionDetails() {
  const exhibitionDetails = getExhibitionDetails()
  // console.log(exhibitionDetails)

  return (
    <PageSection id="details">
      <SectionHeaderRow headline="Wann, wo, wie?" />
      <div className="row">
        <div className="col-12 col-sm-6">
          {exhibitionDetails.map((block) => (
            <React.Fragment key={block._id}>
              <h4>{block.title}</h4>
              <BlockContent blocks={block._rawText} />
            </React.Fragment>
          ))}
        </div>
        <div className="col-12 col-sm-6">
          <iframe
            src="https://maps.google.com/maps?width=100%&height=780&hl=en&q=Stra%C3%9Fe%20des%20Bohrhammers%205%2C%2044623%20Herne+(Kuboshow)&ie=UTF8&t=&z=16&iwloc=B&output=embed"
            width="100%"
            height="100%"
            frameBorder="0"
            marginWidth="0"
            marginHeight="0"
            scrolling="no"
          >
            <a href="https://www.maps.ie/map-my-route/">Plot a route map</a>
          </iframe>
        </div>
      </div>
    </PageSection>
  )
}

export default ExhibitionDetails
