import { graphql, useStaticQuery } from 'gatsby'

export const getExhibitionFeatures = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityExhibitionFeature {
          nodes {
            _id
            title
            text
            circleImage {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                  sizes
                }
              }
            }
          }
        }
      }
    `
  )
  return data?.allSanityExhibitionFeature?.nodes || []
}
